window.renderCaptcha = function() {
  document.grecaptchaClientId = grecaptcha.render('recaptcha_badge', {
    sitekey: "6LcSiyIdAAAAAJiMeV9Rx5wmQ6s9pgTZXo5eClpC",
    badge: 'inline',
    size: 'invisible'
  });
  window.pollCaptchaToken();
}

window.pollCaptchaToken = function() {
  getCaptchaToken();
  setTimeout(window.pollCaptchaToken, 90000);
}

window.getCaptchaToken = function() {
  grecaptcha.execute(document.grecaptchaClientId).then(function(token) {
    $("#recaptcha_badge").attr("data-recaptcha-token", token)
  });
}